import helpersUrl from './python/helpers.py';
import gameUrl from './python/index.py';
import { customPrint } from './helpers.js';

let pyodide;
let gameRunning = false;

window.onload = async () => {
  // The pyodide package is downloaded via a CDN.
  // eslint-disable-next-line no-undef
  pyodide = await loadPyodide();

  // Load the game code
  runGame();
};

const runGame = async () => {
  if (gameRunning) return;
  gameRunning = true;

  const gameCodeResp = await fetch(gameUrl);
  let gameCode = await gameCodeResp.text();

  // Modify the game code to work with the browser.
  gameCode = gameCode.replace(/input\(\)/g, '(await input())');
  gameCode = gameCode.replace(/def play_game\(\):/g, 'async def play_game():');
  gameCode = gameCode.replace(/def print_slow\(/g, 'def print_slo(');
  gameCode = gameCode.replace(/print_slow\(/g, 'await print_slow(');
  gameCode = gameCode.replace(/def move_hint\(/g, 'async def move_hin(');
  gameCode = gameCode.replace(/move_hint\(/g, 'await move_hint(');
  gameCode = gameCode.replace(/def move_hin\(/g, 'def move_hint(');
  gameCode = gameCode.replace(/def game_hint\(/g, 'async def game_hin(');
  gameCode = gameCode.replace(/game_hint\(/g, 'await game_hint(');
  gameCode = gameCode.replace(/def game_hin\(/g, 'def game_hint(');
  gameCode = gameCode.replace(/clear\(\)/g, '');

  const helpersResp = await fetch(helpersUrl);
  const helpersCode = await helpersResp.text();

  const pythonCode = `${helpersCode}\n${gameCode}`;

  try {
    await pyodide.runPythonAsync(pythonCode);
  } catch (error) {
    customPrint(`Error: ${error}`);
  } finally {
    gameRunning = false;
  }
};
